.portfolio-item {
  .card-link {
    .card {
      position: relative;

      border: none;

      &::after {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: map.get($theme-colors, "dark");
        border-radius: $card-border-radius;
        opacity: 0.2;

        transition: all 0.8s ease 0s;

        content: "";
      }

      &-body {
        min-height: 100px;
      }
    }

    &:hover .card::after {
      opacity: 0;
    }
  }
}
