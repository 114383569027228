.tools-container {
  @media (max-width: 768px) {
    height: 250px;
    margin-top: 150px;
  }
}

.tool-img {
  position: absolute;
}

.pug-img {
  top: 160px;
  left: 10%;

  width: 150px;
}

.html-img {
  top: -100px;
  left: calc(50% - 40px);

  width: 80px;
}

.css-img {
  top: -50px;
  left: calc(10% - 40px);

  width: 80px;
}

.js-img {
  top: -50px;
  left: calc(85% - 40px);

  width: 80px;
}

.react-img {
  top: 40px;
  left: calc(25% - 50px);

  width: 150px;
}

.sass-img {
  top: 75px;
  left: calc(60% - 50px);;

  width: 100px;
}

.bootstrap-img {
  top: 150px;
  left: calc(55% - 50px);

  width: 150px;
}

.gulp-img {
  top: 100px;
  left: 80%;

  width: 100px;
}
