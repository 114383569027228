.avatar-img {
  position: relative;

  &::after {
    position: absolute;
    top: 20px;
    left: 20px;

    display: block;
    width: 100%;
    height: 100%;

    border: 2px solid $light;
    border-radius: $border-radius-sm;

    content: "";
  }

  img {
    position: relative;
    z-index: 10;
  }
}
